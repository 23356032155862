



import moment from "moment";
import Axios from "axios";
import Multiselect from "vue-multiselect";
import { Component } from "vue-property-decorator";
// import Datepicker from "vuejs-datepicker";
import { AWS_S3_BASE_URL, BASE_API_URL } from "../../../../../config";
import ExpenseView from "../../../administrator/expense/expense-details/ExpenseView.vue";
import CommonMixin from "../../../../../mixins/common.mixin";
import { AssetDetails } from "../../../../../models/sub-domain/common/assetDetails.model";
import { authHeader } from "../../../../../services/auth";
import DisplayFiles from "../../../../../common-components/sub-domain/views/DisplayFile.vue";
import commonModal from "../../../../../common-components/sub-domain/CommonModals.vue";
import Datepicker from "@hokify/vuejs-datepicker";
import { image } from "html2canvas/dist/types/css/types/image";
import { inactiveDetails } from "../../../../../models/sub-domain/employee.model";
@Component({
  components: {
    Datepicker,
    Multiselect,
    commonModal,
    DisplayFiles,
    ExpenseView,
  },
})
export default class AssetsDetails extends CommonMixin {
  public inputValues: any = new AssetDetails();

  public isActive: any = null;
  public isAssetActive: any = [];
  public submitButton = "Add";
  public addedType: any = null;
  public disablingDates: any = {};
  public organisationStartDate: any = null;
  public categoryList: any = [];
  public categoryArray: any = [];
  public assetRemark:any = null;
  public employeeList: any = [];
  public inProcessing = false;
  public searchArray:any = ["Asset Name","Asset Code"]
  public searchString:any = null;
  public loading:boolean = false;
  public hasAccess:any =[];
  public Processing = false;
  public file: any = [];
  public isDisabled = false;
  public category: any = {};
  public assetList: any = [];
  public terminatedDate: any = [];
  public asset: any = {};
  public inactiveAsset = new inactiveDetails();
  public assetassignee: any = [];
  public deleteAssertId = null;
  public assetDetailID: any = null;
  public inactiveEmpDetails: any = null;
  public searchBy: any = null;
  public assetProperties: any = null;
  public defectDetails:any='';
  public disableAssigneDate:any = null;
  public deleteImages: any = null;
  public deleteAssigneeDetail = null;
  public loader: any = true;
  public image: any = null;
  public empData: any = [];
  public userData: any = [];
  public imageResponse: any = null;
  public allocationHistoryInfo: any = [];
  public AWS_S3_BASE_URL = AWS_S3_BASE_URL;
  public hasMaxImage = false;
  public images = [];
  public i:any = null;
  public filePath:any = {};
  public assetImage:any = null;
  public assestRemark:any = '';
  public employeeJoiningDate: any = null;
  // public disableDates:any=null;
  public doj: any = null;
  public employee: any = [];
  public id = null;
  public loadPage:boolean = false;
  public duplicateSerialNoError: boolean = false;
  public imageArrayLength = 0;
  public checked: boolean = false;
  public assigneeInfo: any = {
    empUserId: null,
    assignedOn: null,
    depositedOn: null,
    dueDate: null,
  };
  $snotify: any;

  public disabledToDates = {
    to: new Date(this.assigneeInfo.dueDate),
  };
  public disabledFromDates = {
    to: new Date(this.assigneeInfo.assignedOn),
  };
  public disableDates = {
    to: new Date(this.terminatedDate),
  };
  $modal: any;

  public disableToDate() {
    this.disabledToDates = {
      to: new Date(this.assigneeInfo.assignedOn),
    };
  }
  public disableDoj() {
    
    if (this.terminatedDate) {
      this.disableDates = {
        to: new Date(this.disableAssigneDate),
      };
    }
  }
  public disableFromDate() {
    this.disabledFromDates = {
      to: new Date(this.assigneeInfo.dueDate),
    };
  }
  public async checkStatus(employee) {
    try {
      // this.employee = emp;
      let emp:any={
        userId:employee,
      }

      // emp.isUserActive = emp.isUserActive ? false : true;
      // this.employeeJoiningDate = new Date(emp.dateOfJoining);

      let response = await Axios.post(
        BASE_API_URL + "administrator/changeStatus",
        {
          emp: emp,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data.status) {
        if(moment(this.employeeJoiningDate) > moment(response.data.changeDate)){
          this.terminatedDate = this.employeeJoiningDate
        }
        else{
          this.terminatedDate = response.data.changeDate;
        }

        if(this.terminatedDate){
        this.disableDoj();
        }
        // if(this.inactiveEmpDetails.type=="Suspended"){}
        // this.terminatedDate = response.data.changeDate;
        // this.terminatedDate = new Date(this.terminatedDate);
        // this.inactiveEmpDetails.date.from = new Date(this.terminatedDate);
        // this.inactiveEmpDetails.date.to = new Date(this.terminatedDate);
        // if(this.inactiveEmpDetails.date.from < this.inactiveEmpDetails.date.to){
        // }
      }

      
    } catch (error) {
      console.log(error);
    }
  }
  public async getEmployeeList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/getEmployeeList",
        {
          headers: authHeader(),
        }
      );

      this.employeeList = response.data;
    } catch (error) {
      console.log(error);
    }
  }
  /**
   * Fetching the category
   */
  public async fetchCategory() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/fetchCategory",
        {
          headers: authHeader(),
        }
      );
      this.categoryList = response.data;
      for (let i = 0; i < this.categoryList.length; i++) {
        this.categoryArray.push(this.categoryList[i].categoryName);
        // if(this.categoryArray==this.categoryList[i]._id){
        //   this.categoryArray.push(this.categoryList[i].categoryName);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public toAddDetails() {
    this.inputValues = new AssetDetails();
    this.$modal.show("assetDetailModel");
    this.submitButton = "Add";
    this.addedType = "Add Asset Allocation";
  }
  public async closeModal() {
    this.$modal.hide("assetDetailModel");
    this.$modal.hide("assetAssigneeModel");
    this.$modal.hide("loaderModel");
    this.$modal.hide("statusModel");
    this.$modal.hide("activate");

    this.submitButton == "Add";
    this.inProcessing = false;
    this.hasMaxImage = false;
    this.duplicateSerialNoError = false;
    this.imageArrayLength = 0;
    this.inputValues = new AssetDetails();
    this.getAssetList();
  }

  public uplaodPicture(event) {
    try {
      if (!this.$store.state.sessionObject.bucketName) {
        this.$snotify.remove();
        this.$snotify.error("cannot upload bucket name not coming");
        return;
      }
      if (event.target.files.length + this.imageArrayLength > 4) {
        this.$snotify.remove();
        this.$snotify.error("cannot upload more than four images");
        return;
      }

      // this.inputValues.assetImage = [];
      if (event.target.files.length > 0 && event.target.files.length <= 4) {
        Object.values(event.target.files).forEach((element: any) => {
          this.inputValues.assetImage.push(element);
        });
        this.imageArrayLength = this.imageArrayLength + 1;
      }

      this.showLocalFiles(this.inputValues.assetImage);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  show local files
   */
  public showLocalFiles(files) {

    try {
      let dom: any = document.getElementById("asset-images-box");

      // dom.innerHTML = null;

      files.forEach((element) => {
        let image = document.createElement("img");

        image.src = URL.createObjectURL(element);
        image.className = "col-12 col-lg-6 col-md-6 col-sm-6 px-2";
        if (dom) {
          dom.appendChild(image);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  public async changeStatus(asset) {
    this.asset = asset;
    this.inputValues.status=null
    if (asset.isAssetActive) {
      this.$modal.show("statusModel");
    } else {
      this.$modal.show("activate");
    }
  }
  public async changeAssetStatus() {
    try {
      if (this.asset.assigneeInfo.empUserId == null) {
        this.asset.isAssetActive = false;
      }
      let response = await Axios.post(
        BASE_API_URL + "administrator/changeAssetStatus",
        {
          status: this.inputValues.status,
          asset: this.asset,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data.status == 300) {
        this.$snotify.error(response.data.message);
      } else {
        this.asset = null;
        this.inactiveAsset = new inactiveDetails();
        this.$snotify.success(response.data.message);
      }
      this.$modal.hide("statusModel");
      this.getAssetList();
    } catch (error) {
      console.log(error);
    }
  }

  public async activateAsset() {
    try {
      this.asset.isAssetActive = true;
      let response = await Axios.post(
        BASE_API_URL + "administrator/activateAsset",
        {
          asset: this.asset,
          status: this.inputValues.status,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.data.status) {
        this.asset = null;
        this.$snotify.success(response.data.message);
        this.$modal.hide("activate");
      }
      this.getAssetList();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  save asset details
   */
  public async saveAssetDetails() {
    try {
      this.inputValues.isAssetActive = true;
      this.inProcessing = true;
      let formData = new FormData();
      // this.categoryList.forEach(e=>{
      //   if(e.categoryName==this.inputValues.assetCategoryId){
      //     this.inputValues.assetCategoryId=e._id;
      //   }
      // })
      this.inputValues.assetImage.forEach((element: any) => {
        formData.append("file", element);
      });

      formData.append("data", JSON.stringify(this.inputValues));
      formData.append("bucketName", this.$store.state.sessionObject.bucketName);
      formData.append("type", "asset");
      formData.append("submitType", this.submitButton);

      let response = await Axios.post(
        BASE_API_URL + "administrator/saveAssetDetails",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.data.status == 300) {
        this.duplicateSerialNoError = true;
        this.inProcessing = false;
        return;
      } 
      else if (response.data.status == 500){
        this.$snotify.clear()
        this.$snotify.warning(response.data.message)
      }
      else {
        this.duplicateSerialNoError = false;
        this.$snotify.remove();
        this.$snotify.success(response.data.message);
        // setTimeout(async () => {
        await this.getAssetList();
        this.inProcessing = false;
        // }, 2000);
      }
      this.closeModal();
    } catch (error) {
      console.log(error);
    }
    this.inProcessing = false;
    this.hasMaxImage = false;
  }

  nameWithId(employeeId) {
    return `${employeeId.name} — [${employeeId.employeeId}]`;
  }

  /**
   *  get asset list
   */
  public async getAssetList() {
    try {
      this.assetList = []
     
      let response = await Axios.post(
        BASE_API_URL + "administrator/getAssetList",
        {
          searchBy:this.searchBy,
          searchString:this.searchString
        },
        {
          headers: authHeader(),
        }
      );
      this.assetList = response.data.result;
    
    } catch (error) {
      console.log(error);
    }
  }

  public clearSearch(){
    this.searchBy=null,
    this.searchString = null
    this.getAssetList()
  }

  /**
   *  delete Asset Image
   */
  public async deleteAssetImagePopup(i,filePath){
    this.$modal.show("deleteAssetImage")
    this.i = i;
    this.filePath = filePath
  }
  public async deleteAssetImage() {
    let abc =(document.getElementById("removeImages") as HTMLInputElement).value = ''
    await Axios.post(
      BASE_API_URL + "administrator/deleteAssetImage",
      {
        data: this.inputValues.assetImage[this.i],
        assetId: this.inputValues._id,
        filePath: this.filePath,
      },
      {
        headers: authHeader(),
      }
    );
    
    this.deleteImages = document.getElementById("asset-images-box") as HTMLInputElement;
    // if (this.deleteImages.childNodes[this.i]) {
      // this.deleteImages.removeChild(this.deleteImages);
      this.inputValues.assetImage.splice(this.i, 1);
      this.$modal.hide("deleteAssetImage");
    //   let img = document.getElementById("imageEl"+this.i) as HTMLInputElement
    // // img.src = ''
    // img.innerHTML= ""
      this.imageArrayLength--;
      await this.getAssetList()
    // }
    //
  }

  /**
   *  check assign asset to employee
   */
  // public async checkAssignAssetToEmployee() {
  //   let currentDate = moment(new Date()).format("YYYY-MM-DD");

  //   this.assetList.forEach((e) => {
  //     if (
  //       e.assetCategoryId.toString() ===
  //       this.inputValues.assetCategoryId.toString()
  //     ) {
  //       let depositedOn = moment(e.assigneeInfo.depositedOn).format(
  //         "YYYY-MM-DD"
  //       );
  //       let dueDate = moment(e.assigneeInfo.dueDate).format("YYYY-MM-DD");
  //       if (!e.assigneeInfo.depositedOn || currentDate < depositedOn)
  //         this.$snotify.remove();
  //       this.$snotify.error(
  //         "Asset allocation is already added against this asset category"
  //       );
  //     }
  //   });
  // }
  /**
   *  edit asset details
   */
  public async editAssetDetails(assetData) {
    let data = JSON.parse(JSON.stringify(assetData));
    let files = data.assetImage.map((a) => a.path);

    this.inputValues = data;
    this.categoryList.forEach((e) => {
      if (e._id == data.assetCategoryId) {
        this.inputValues.assetCategoryId = e.categoryName;
      }
    });
    // this.inputValues.assigneeInfo.depositedOn = this.inputValues.assigneeInfo
    //   .depositedOn
    //   ? moment(this.inputValues.assigneeInfo.depositedOn).format("DD MMM YYYY")
    //   : "";

    // if (
    //   this.inputValues.assigneeInfo.assignedOn &&
    //   !this.inputValues.assigneeInfo.depositedOn
    // ) {
    //   this.inputValues.assigneeInfo.hasChange = true;
    // }

    this.$modal.show("assetDetailModel");
    if(files.length>0){
      this.openLoader(true, 1);
    }
    
    this.addedType = "Edit Asset Allocation";
    this.submitButton = "Update";
    this.assetList = JSON.parse(JSON.stringify(this.assetList));
    if (files.length > 0) {
      files.length > 2 ? (this.hasMaxImage = true) : (this.hasMaxImage = false);
      this.imageArrayLength = files.length;
      await this.fetchImages(files);
    }
    // this.openLoader(false, null);
  }

  // to save the add edit assignee details
  public async saveAssigneeDetails() {
    this.Processing = true;
    let assigneeDetails = {
      checked: this.checked,
      assetDetailID: this.assetDetailID,
      addedType: this.addedType,
    };
    assigneeDetails.addedType == "Add"
      ? this.callLoader(true, 2)
      : this.callLoader(true, 3);

    let response = await Axios.post(
      BASE_API_URL + "administrator/saveAssigneeDetails",
      assigneeDetails,
      {
        headers: authHeader(),
      }
    );
    if (response.data.status == 200) {
      this.$snotify.remove();
      this.$snotify.success(response.data.message);
      
      this.closeModal();
      this.Processing = false;
      this.callLoader(false, null);
      await this.getAssetList()
    }
  }
  //To call Loader
  public callLoader(loader, titleId) {
    this.$store.state.fusionLoader = loader;
    this.$store.state.loaderTitleId = titleId;
  }
  /**
   *  assign and edit asset to employee
   */
  // public async addEditAssetAssignee(assetDetailId, assigneeInfo, action) {
  //   try {
  //     this.assetList.forEach(asset=>{
  //       if(asset._id == assetDetailId){
  //         this.disableAssigneDate = asset.purchaseOn
  //       }
  //     })
  //     // await this.nameWithId(assigneeInfo.employeeUserId);
  //     let data = Array();
  //     let name=null;
  //     // let e:any=null;
  //     this.employeeList.forEach((e: any) => {
  //       if (e._id == assigneeInfo.empUserId) {
  //         data.push(e);
  //        name = e.name;
         
  //       }
  //     });
  //     // assigneeInfo.empUserId=await this.nameWithId(data);
  //     this.$modal.show("assetAssigneeModel");
  //     this.addedType = action == "Add" ? "Assign Asset" : "Assign Asset";
  //     this.submitButton = action == "Add" ? "Save" : "Submit";
  //     // this.Processing=false;
  //     this.checked = false;
  //     // if(this.assigneeInfo.depositedOn!=null)
  //     // {
  //     //   this.addedType='Add';
  //     // }
  //     this.assigneeInfo.empUserId=name;
  //     this.assetDetailID = assetDetailId;
  //     this.assigneeInfo = assigneeInfo;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  /**
   *  fetch image
   */
  public async fetchImages(arr) {
    try {
      this.openLoader(true, 1);
      this.loader = true;
      let response = await Axios.post(
        BASE_API_URL + "administrator/fetchImages",
        { arr },
        { headers: authHeader() }
      );
      this.imageResponse = response.data;
      let dom: any = document.getElementById("asset-images-box");
      response.data.forEach((element) => {
        this.image = document.createElement("img");
        this.image.src = element;
        this.image.className = "col-12 col-lg-6 col-md-6 col-sm-6 px-2";
        if (dom) {
          dom.appendChild(this.image);
        }
      });
      this.loader = false;
      this.openLoader(false, null);
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  view allocation history
   */
  public async viewAllocationHistory(id) {
    this.$modal.show("allocationHistory");
    try {
      let response = await Axios.post(
        BASE_API_URL + "administrator/viewAllocationHistory",
        { id },
        {
          headers: authHeader(),
        }
      );

      this.allocationHistoryInfo = response.data.reverse();
    } catch (error) {
      console.log(error);
    }
  }

  //fetch image in list
  public async loadImages(path, i, divId) {
    try {
      // if (divId == "imageEl" && (this.submitButton == "Update" || this.id))
      //   return;
      let response = await Axios.post(
        BASE_API_URL + "administrator/fetchDocument",
        { path: path },
        { headers: authHeader() }
      );
      let dom: any = document.getElementById(divId + i);
      let img = document.createElement("img");
      img.src =
        "data:image/" + path.split(".").pop() + ";base64," + response.data;
      img.id = "img-id" + i;
      dom.innerHTML = null;
      if (dom) {
        dom.appendChild(img);
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Open the model for deleteing the assert
   * */
  public openDeletePopup(id) {
    this.$modal.show("deleteDetails");
    this.deleteAssertId = id;
  }

  /**Deleting the assignee function  */
  // public async deleteAssetAssignee(assetDetailId, assigneeInfo) {
  //   try {
  //     await this.$refs.ref["confirmationBox"]({
  //       msg: `Are you sure you want to delete assignee?`,
  //       data: { id: assetDetailId },
  //       loaderId: 4,
  //       apiPath: "administrator/deleteAssetAssignee",
  //       assetDetailId,
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  /**Deleting the asset function  */
  public async deleteAsset() {
    try {
      // let response = await Axios.post(
      //   BASE_API_URL + "administrator/deleteAsset",
      //   { userId: this.deleteAssertId },

      //   {
      //     headers: authHeader(),
      //   }
      // );
      // this.$modal.hide("deleteDetails");
      // if (response.data.code == 200) {
      //   this.$snotify.remove();
      //   this.$snotify.success(response.data.message);
      // } else {
      //   this.$snotify.remove();
      //   this.$snotify.error(response.data.message);
      // }
      this.getAssetList();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   *  Delete Image
   */
  public async deleteImage(id, file) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/deleteImage",
        { id: id, file: file, source: "assetDetails" },
        { headers: authHeader() }
      );

      if (response.data) {
        this.$modal.hide("showAndDeleteImage");
        this.$snotify.remove();
        this.$snotify.success("Image deleted successfully");
        this.id = null;
        this.images = [];
        //setTimeout(() => {
        await this.getAssetList();
        // }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async showDescription(assetData,assetRemark,asset) {
    let data = JSON.parse(JSON.stringify(asset));
    let files = data.assetImage.map((a) => a.path);
    this.assetImage = files.length
    this.assetProperties = assetData;
    this.assetRemark = assetRemark;
    // if(asset.defect !=null)
    this.defectDetails = asset.defect
    this.$modal.show("showDescription");
    this.assestRemark = asset.status ? 'Remark : ' + asset.status : '';

    if (this.assetProperties == "") {
      this.assetProperties = "No Data";
    }
    if(files.length>0){
      this.openLoader(true, 1);
    }
     if (files.length > 0) {
      files.length > 2 ? (this.hasMaxImage = true) : (this.hasMaxImage = false);
      this.imageArrayLength = files.length;
      await this.fetchImages(files);
    }
    
    
  }

  /**
   *  Open Image Model
   */
  public async openImageModal(file, id) {
    try {
      if (file.path){
        // let arr = file.map((a) => a.path);
        let arr:any = [];
        arr.push(file.path)
        this.$refs.displayFiles["displayDocuments"](arr, "Asset Image");
      }else{
        this.$refs.expenseView["viewExpenseLocalBill"](file);
        this.$modal.show("showLocalBill");
      }
   
      
    } catch (error) {
      console.log(error);
    }
  }

  public closeImageModal() {
    this.images = [];
    this.id = null;
    this.$modal.hide("showAndDeleteImage");
  }

  // public checkDuplicateAssignDate() {
  //   this.assetList.forEach((e) => {
  //     let assignDate = moment(e.assigneeInfo.assignedOn).format("DD-MM-YYYY");
  //     let depositDate = moment(e.assigneeInfo.depositedOn).format("DD-MM-YYYY");

  //     let selectedAssignDate = moment(
  //       this.inputValues.assigneeInfo.assignedOn
  //     ).format("DD-MM-YYYY");

  //     if (
  //       e.assetName == this.inputValues.assetName &&
  //       selectedAssignDate == assignDate
  //     ) {
  //       this.$snotify.remove();
  //       this.$snotify.error(
  //         "This asset already assigned to this date. Please choose another assigned date  "
  //       );
  //       this.inputValues.assigneeInfo.assignedOn = null;
  //       return;
  //     }

  //     if (
  //       e.assetName == this.inputValues.assetName &&
  //       selectedAssignDate > assignDate &&
  //       selectedAssignDate < depositDate
  //     ) {
  //       this.$snotify.remove();
  //       this.$snotify.error(
  //         "This asset still not deposit. Please choose another assigned date  "
  //       );
  //       this.inputValues.assigneeInfo.assignedOn = null;
  //       return;
  //     }
  //   });
  // }
  public openLoader(loader, titleId) {
    this.$store.state.fusionLoader = loader;
    this.$store.state.loaderTitleId = titleId;
  }
    public async getOrganisationInfo() {
    try {
      
      let response = await Axios.get(
        BASE_API_URL + "employee/getOrganisationInfo",
        {
          headers: authHeader(),
        }
      );
      this.organisationStartDate = response.data.data
        ? response.data.data.startedOn
        : null;
    } catch (error) {
      console.log(error);
    }
  }

  async beforeMount(){
    this.hasAccess = this.$store.state.sessionObject.crudRight ? this.$store.state.sessionObject.crudRight:{}
   
  }

  async mounted() {
  
      this.$store.state.fusionLoader = true;
      this.$store.state.loaderTitleId = 1;
      
    await this.getEmployeeList();
    await this.fetchCategory();
    await this.getOrganisationInfo()
    await this.getAssetList();
       let date = new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
      0,
      0,
      0,
      0
    );
    let orgDate = new Date(this.organisationStartDate).setHours(0, 0, 0, 0);
    this.disablingDates = {
      from: new Date(date),
      to: new Date(orgDate),
    };
    this.loading = true;
    this.$store.state.fusionLoader =false;


   


    // await this.checkStatus(this.employee);
  }
}
