

  class UploadPitcures {
    constructor() {
        this.path = null;
    }

    public path: any;

}
  export class AssetDetails {
   
    constructor() {
        this.assetCategoryId = null
        this.assetName = null;
        this.assetDescription = null;
        this.assetCost = null;
        this.assetDetails=null;
        this.status=null;
        this.assetBrand = null;
        this.assetImage = new Array<UploadPitcures>();
        this.purchaseOn = new Date();
        this.purchaseFrom = null;
        this.serialNumber = null;
        this.isAssetActive =null;
        this.defect = null;
        this.allocationHistory= [];
        this.assetCode =null;
    }
    public assetCategoryId: any;
    public assetName: any;
    public assetDescription: any;
    public assetCost: any;
    public assetDetails: any;
    public assetBrand: any;
    public assetImage: Array<UploadPitcures>;
    public purchaseOn: Date;
    public purchaseFrom: any;
    public status:any;
    public serialNumber: any;
    public defect: any;
    public isAssetActive: any;
    public allocationHistory: Array<any>
    public assetCode :any;
}