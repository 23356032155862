





































import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class Expense extends Vue {
  public base64String: any = null;
  @Prop()
  private from;
  @Prop()
  private source;
  private loader = true;


  /**
   * To view the bill which is not saved
   */
  public async viewExpenseLocalBill(files) {
    try {
      setTimeout(() => {
        this.base64String = null;
        let dom: any = document.getElementById("document-viewer");

        dom.innerHTML = "";

        let type = files.type.toLowerCase();

        if (type == "application/pdf") {
          let image = document.createElement("iframe");
          image.height = "100%";
          image.width = "100%";
          image["src"] = `data:application/pdf;base64,`;
          this.base64String = `data:application/pdf;base64,`;
          image.src = URL.createObjectURL(files);

          image.className = "col-12 col-lg-12 col-md-4 col-sm-6";
          this.loader = false;
          dom.appendChild(image);
        } else if (
          type == "image/jpg" ||
          type == "image/jpeg" ||
          type == "image/png"
        ) {
          let image = document.createElement("img");
          image.src = URL.createObjectURL(files);
          image.onload = () => {
            dom.style.height = image.height;
            dom.style.width = image.width;
          };
          this.loader = false;
          dom.appendChild(image);
        } else {
          dom.innerHTML = "Cannot display this file format";
        }
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  }
  private hideModal() {
    try {
      this.$modal.hide("showLocalBill");
      this.loader = true;
    } catch (error) {
      console.log(error);
    }
  }
}
