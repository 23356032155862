























































import { Component, Vue, Prop } from "vue-property-decorator";
import { BASE_API_URL, AWS_S3_BASE_URL, EventBus } from "../../config";
import { authHeader } from "../../services/auth";
import Axios from "axios";

@Component
export default class CommonModals extends Vue {
  public message = null;
  public aPIPath = null;
  public currentData: any = null;
  public loaderId = null;
  public inProcess = false;
  /**
   * Delete Confirmation
   */
  public async confirmationBox(body) {
    try {
      this.message = body.msg;

      this.aPIPath = body.apiPath;

      this.currentData = body.data;
      this.loaderId = body.loaderId;
      await this.$modal.show("confirmationModal");
    } catch (error) {
      console.log(error);
    }
  }


  public async userChoice(userChoice) {
    try {
      if (userChoice == "NO") {
        await this.$modal.hide("confirmationModal");
        if (
          this.currentData.timesheetData &&
          this.currentData.timesheetData.type != "Publish"
        ) {
          this.$emit("callMountFunction");
        }
      } else {
        this.openLoader(true, this.loaderId);
        this.inProcess = true;
        let res = await Axios.post(
          BASE_API_URL + this.aPIPath,
          this.currentData,
          { headers: authHeader() }
        );
        if (res.data.code == 200) {
          this.$snotify.clear();

          await this.$snotify.success(res.data.message);
          await this.$modal.hide("confirmationModal");
        }
        else if(res.data.status == 320){
          this.$snotify.clear();

          await this.$snotify.error(res.data.message)
        }
       else if (res.data.code == 201) {
        this.$snotify.clear();

          await this.$snotify.warning(res.data.message);
          await this.$modal.hide("confirmationModal");
        } else {
          this.$snotify.clear();

          await this.$snotify.success(res.data.message);
          await this.$modal.hide("confirmationModal");
        }
        if (
          this.currentData.timesheetData &&
          this.currentData.timesheetData.type == "Publish"
        ) {
          this.$emit("callNextFunction");
        } else {
          this.$emit("callMountFunction");
        }
        EventBus.$emit("refreshBooklet", true);
      }
    } catch (error) {
      console.log(error);
    }
    this.inProcess = false;
    this.openLoader(false, null);
  }

  //To open Loader
  public openLoader(loader, titleId) {
    this.$store.state.fusionLoader = loader;
    this.$store.state.loaderTitleId = titleId;
  }

  /**
   * REfresh
   */
  public refresh() {
    this.$router.go(0);
  }
}
