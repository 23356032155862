























































import { Vue, Component, Prop } from "vue-property-decorator";
import { BASE_API_URL, EventBus } from "../../../config";
import Axios from "axios";
@Component({})
export default class DisplayFiles extends Vue {
  public fileToDisplay: any = null;
  public showNext: any = null;
  public showPrev: any = null;
  public fileType: any = null;
  public fileIndex: any = null;
  public fileArray: any = [];
  public base64String: any = null;
  public hasAccess_:any = [];
  public downloadAccess: boolean = true;

  public policyName: any = "View Document";
  @Prop()
  private from;
  @Prop()
  private source;
  private loader = true;

  public downLoadImages() {
    
    if (this.base64String) {
      const d = document.createElement("a");
      document.body.appendChild(d);
      d.href = this.base64String;
      d.target = "_self";
      d.download = "EventImages";
      d.click();
    }
  }

  public displayDocuments(doc, policyName) {
    try {      
      this.fileArray = doc;
      this.fileIndex = 0;
      this.policyName = policyName;
      this.showPrev = false;
      if (this.fileArray.length > 1) {
        this.showNext = true;
      }
      this.fileToDisplay = this.fileArray[this.fileIndex];
      this.fileType = this.fileToDisplay
        .split(".")
        .pop()
        .toLowerCase();
      if (this.fileType == "pdf" || this.fileType == "js viewer") {
        this.loadPdf(this.fileToDisplay);
      }   
      if (
        this.fileType == "jpg" ||
        this.fileType == "jpeg" ||
        this.fileType == "png"
      ) {
        this.loadImage(this.fileToDisplay, this.fileType, policyName );
      }
      this.$modal.show("toSeeAttachment" + this.from);
      
      if ((this.fileType == "docx" || this.fileType == "js viewer")) {
        // Doc file can't display i will automatic download
        this.loadDoc(this.fileToDisplay);
      } 
    
    } catch (error) {
      console.log(error);
    }
  }
  public hideModal() {
    try {
      this.$modal.hide("toSeeAttachment" + this.from);
      EventBus.$emit("someChanges");
      this.fileToDisplay = null;
      this.showNext = null;
      this.showPrev = null;
      this.fileType = null;
      this.fileIndex = null;
      this.fileArray = [];
      this.loader = true;
    } catch (error) {
      console.log(error);
    }
  }

  public async loadPdf(path) {
    try {
      this.base64String = null;
      let response = await Axios.post(
        BASE_API_URL + "leaveManagement/viewLeavePolicyFile",
        { path }
      );
      let domParent: any = document.getElementById("document-viewer");
      domParent.innerHTML = "";
      let dom: any = document.createElement("iframe");
      dom.height = "100%";
      dom.width = "100%";
      dom["src"] = `data:application/pdf;base64,` + response.data;
      this.base64String = `data:application/pdf;base64,` + response.data;
      domParent.appendChild(dom);
      this.loader = false;
    } catch (error) {
      console.log(error);
    }
  }

  public async loadDoc(path) {
    try {
      this.base64String = null;
      let response = await Axios.post(
        BASE_API_URL + "leaveManagement/viewLeavePolicyFile",
        { path }
      );
      let domParent: any = document.getElementById("document-viewer");
      domParent.innerHTML = "";
      let dom: any = document.createElement("iframe");
      dom.height = "100%";
      dom.width = "100%";
      dom["src"] = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,` + response.data;
      this.base64String = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,` + response.data;
      domParent.appendChild(dom);
      this.loader = false;
      this.hideModal();

    } catch (error) {
      console.log(error);
    }
  }

  public async loadImage(path, type, policyName) {
    try {
      
      path = path.replace(/original/g, "medium");
      this.policyName = policyName;
      this.base64String = null;
      let response = await Axios.post(
        BASE_API_URL + "leaveManagement/viewLeavePolicyFile",
        { path }
      );
      setTimeout(() => {
        let domParent: any = document.getElementById("document-viewer");
        domParent.innerHTML = "";
        let dom: any = document.createElement("img");
        dom["src"] = `data:image/${type};base64,` + response.data;
        this.base64String = `data:image/${type};base64,` + response.data;
        let img = new Image();
        img.src = this.base64String;
        img.onload = () => {
          dom.style.height = img.height;
          dom.style.width = img.width;
        };
        domParent.appendChild(dom);
        this.loader = false;
      }, 10);
    } catch (error) {
      console.log(error);
    }
  }

  public async displayMessage(message, policyName) {
    try {
      this.$modal.show("toSeeAttachment" + this.from);
      setTimeout(() => {
        let domParent: any = document.getElementById("document-viewer");
        domParent.innerHTML = message;
        domParent.append();
        this.loader = false;
      }, 100);
      this.policyName = policyName;
    } catch (error) {
      console.log(error);
    }
  }

  public changeImageIndex(check) {
    if (check == "next") {
      this.fileIndex++;
      this.showPrev = true;
      this.fileToDisplay = this.fileArray[this.fileIndex];
      this.fileType = this.fileToDisplay.split(".").pop().toLowerCase();
      if (this.fileArray.length == this.fileIndex + 1) {
        this.showNext = false;
      }
    }
    if (check == "previous") {
      this.fileIndex--;
      this.showNext = true;
      this.fileToDisplay = this.fileArray[this.fileIndex];
      let length = this.fileToDisplay.length;
      this.fileType = this.fileToDisplay.split(".").pop().toLowerCase();
      if (this.fileIndex == 0) {
        this.showPrev = false;
      }
    }
    if (this.fileType == "pdf") {
      this.loadPdf(this.fileToDisplay);
    }
    if (
      this.fileType == "jpg" ||
      this.fileType == "jpeg" ||
      this.fileType == "png"
    ) {
      this.loadImage(this.fileToDisplay, this.fileType, this.policyName);
    }
  }

  async beforeMount(){
    this.hasAccess_ = this.$store.state.sessionObject.crudRight ? this.$store.state.sessionObject.crudRight:{}
    if(Object.keys(this.hasAccess_).length && !this.hasAccess_.isEdit){
      this.downloadAccess = false;
    }else{
      this.downloadAccess = true;
    }
  }
}
