import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import { Employee } from "../models/sub-domain/employee.model";
import Axios from "axios";
import moment from "moment";

@Component
export default class CommonMixin extends Vue {
  public employeeInfo: any = this.$store.state.employeeConfig;
  public origEmployeeInfo = {}
  public selectedUser: any = [];
  public users: any = [];
  public selectType: any = null;
  public qualifiactionList: any = []
  public list: any = {
    roleList: []
  };
  public duplicateError = {
    hasEmpId: false,
    hasEmail: false,
    hasPhone: false,
    hasWorkSchedule: false,
    hasDesignation: false,
    hasDepartment: false,
    hasProject: false,
    hasRole: false,
    hasDate: false,
    hasOccassion: false,
    hasSameYearOccaison: false
  };
  public returnValue = null;
  public joiningDate: any = null;
  public additionalProjects: any = [];
  public reportees: any = [];
  public filteredAdditionalReportee: any = []

  // public  hasEmp =  false

  public async getSelectedOptionDetail(selectType) {
    try {
      this.selectedUser = [];
      this.selectType = selectType;

      if (selectType == "All") {
        this.users = [
          {
            refId: "All",
            name: "All"
          }
        ];
        return;
      }
      let response = await Axios.post(
        BASE_API_URL + "common/getSelectedOptionDetail",
        {
          selectType: selectType
        },
        {
          headers: authHeader()
        }
      );
      
      // this.users = selectType.from == 'Timesheet' ? response.data.timesheetEmployeeList.list : response.data.data;
      if(selectType.from == 'Timesheet'){
        // this.users = response.data.timesheetEmployeeList.list;
        
        // To remove duplicate reportee names from manager's Reportee Timesheet tab's search employee list, if reportee reported to primary and secondary project report to same manager
        let unique = {}
        if(response.data.timesheetEmployeeList) {
          this.users = response.data.timesheetEmployeeList.list.filter(obj => !unique[obj.employeeUserId] && (unique[obj.employeeUserId] = true));
        }

      }
      else{
        this.users = response.data.data
      }
      if(this.users && this.users.length > 0) {
        this.users.forEach(e => {
          e.name = this.$lodash.startCase(e.name);
        });
            }

      this.joiningDate = selectType.from == 'Timesheet' ? response.data.timesheetEmployeeList.joiningDate : null
    } catch (error) {
      console.log(error);
    }
  }

  //to close meesage modal
  public async cancelChange() {
    this.$modal.hide("messageModal");
  }

  public async loadImage(path, i) {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/fetchDocument",
        { path: path },
        { headers: authHeader() }
      );
      let dom: any = document.getElementById("imageEl" + i);
      let img = document.createElement("img");
      img.src =
        "data:image/" + path.split(".").pop() + ";base64," + response.data;
      img.id = "img-id" + i;
      if (dom) {
        dom.innerHTML = null;
        dom.appendChild(img);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public callLoader(loader, loaderTitleId) {
    this.$store.state.loaderTitleId =loaderTitleId ;
    this.$store.state.fusionLoader = loader;
  }

  public async fetchInformationOfEmployee() {
    try {
      // this.callLoader(true, 1);
      let userId = this.$route.query.userId
        ? this.$route.query.userId
        : this.$userId;
      let response = await Axios.post(
        BASE_API_URL + "common/fetchEmployeeDetails",
        { id: userId },
        {
          headers: authHeader()
        }
      );
      this.employeeInfo = response.data.data
        ? response.data.data
        : new Employee();


        this.list.roleList = response.data.roleList ? response.data.roleList : [];
        //define moments for the startdate and enddate
        let dateOfJoining = moment(this.employeeInfo.personalInformation.dateOfJoining);
        let curreDate = moment()
        let days = curreDate.diff(dateOfJoining, 'years');
        let beforeJoiningExpInMonth = this.employeeInfo.personalInformation.yearOfExperience * 12 + this.employeeInfo.personalInformation.monthOfExperience; 
        let years = curreDate.diff(dateOfJoining, 'years');
        let months = curreDate.diff(dateOfJoining, 'months') - (years * 12);
        let afterJoiningExpInMonth = years * 12 + months;  
  
        let totalExpInMonth = beforeJoiningExpInMonth + afterJoiningExpInMonth;
  
        let yearsOfExperience = Math.floor (totalExpInMonth / 12);
        let monthsOfExperience = totalExpInMonth % 12;
        
        // If joining date is future date then show current experience
        if(dateOfJoining <= curreDate){
          this.employeeInfo.personalInformation.yearOfExperience = yearsOfExperience + " Year(s) " + monthsOfExperience + ' Month(s)'
        }
        else{
          this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + " Year(s) " + this.employeeInfo.personalInformation.monthOfExperience + ' Month(s)'
        }
  
  
      
/*
      if (days == 0 && this.employeeInfo.personalInformation.yearOfExperience == 0) {
        let month = curreDate.diff(dateOfJoining, 'month');
        let currentY = moment(curreDate).format("yy")
        let joiningy = moment(dateOfJoining).format("yy");

        if (currentY < joiningy) {
          this.employeeInfo.personalInformation.yearOfExperience = '0 Month(s)'
        }
        else {
          if(Math.sign(month) == -1){
            this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + ' Month(s)'
          }
          else{
            this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + month + ' Month(s)'
          }
        }
      } else {
        // let number = moment(curreDate).format("MM");
        // let number1 = moment(dateOfJoining).format("MM");
        // let totalMonth = '12';
        // // let monthlyExperience = parseInt(totalMonth) - parseInt(number1);
        // // let excludeCurrMonth = parseInt(number) - 1;
        // let joiningMonth = parseInt(totalMonth) - parseInt(number1) + parseInt(number);
        // if(joiningMonth >= 12){

        //   joiningMonth = joiningMonth - 12 

        //   if(joiningMonth == 0){ // If month = 0 then show experience in years only
        //     this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + days  + " Year(s) "
        //   }
        //   else{
        //     this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + days  + " Year(s) " + joiningMonth + ' Month(s)'
        //   }
          
        // }
        // else{
        //   this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + days + " Year(s) " + joiningMonth + ' Month(s)'
        // }

        // If joining date is future date
        if(dateOfJoining.isAfter(curreDate)){
          this.employeeInfo.personalInformation.yearOfExperience = "0 Year(s) 0 Month(s)"
        }
        else if (dateOfJoining.isValid() === true && curreDate.isValid() === true) {
          //getting the difference in years
          let years = curreDate.diff(dateOfJoining, 'years');

          //moment returns the total months between the two dates, subtracting the years
          let months = curreDate.diff(dateOfJoining, 'months') - (years * 12);

          //to calculate the days, first get the previous month and then subtract it
          // dateOfJoining.add(years, 'years').add(months, 'months');
          // let day = curreDate.diff(dateOfJoining, 'days')

          if(months == 0){
            this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + days + " Year(s) "
          }else{
            this.employeeInfo.personalInformation.yearOfExperience = this.employeeInfo.personalInformation.yearOfExperience + days + " Year(s) " + months + ' Month(s)'
          }
        }
      }
// */
//       let size = 3
//       if (this.employeeInfo && this.employeeInfo.reportTo) {
//         this.reportees = this.employeeInfo.reportees.slice(0, size)

//       }

//       if (this.employeeInfo && this.employeeInfo.additionalProjects) {
        
//        this.filteredAdditionalReportee =  this.employeeInfo.additionalProjects.filter((a, i) =>
//           i === this.employeeInfo.additionalProjects.length - 1 ||
//           a.projectHeadName.toLowerCase() !== this.employeeInfo.additionalProjects[i + 1].projectHeadName.toLowerCase()
//         );

//         // this. filteredAdditionalReportee = this.filteredAdditionalReportee.filter((e) => e.projectHead != this.employeeInfo.reportTo[0]._id)
        
//         this.additionalProjects = this.employeeInfo.additionalProjects.slice(0, size)

//       }
      
      this.origEmployeeInfo = JSON.stringify(this.employeeInfo)
      // this.callLoader(false, null);
    } catch (error) {
      console.log(error);
    }
    // this.hasEmp= false

  }

  async mounted() {
    if (this.$route.query.userId) {
      this.fetchInformationOfEmployee();
    }
  }
}